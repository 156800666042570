<style scoped>
	.dialog_page_header {
		padding: 3px;
	}
	.dialog_page_footer {
		text-align: right;
	}
	.community_box {
		max-height: 95vh;
	}
	.ddi {
		padding: 5px 0;
	}
	.c_box {
		display: flex;
		height: 100%;
	}
	.c_box_item {
		height: 100%;
		overflow-y: auto;
		flex: 1;
		padding: 10px 0;
		box-sizing: border-box;
		overflow-x: hidden;
	}
	.c_box .c_box_item:nth-child(2) {
		border-left: 1px solid #ccc;
	}
	.pitem-box {
		width: 100%;
		display: flex;
	}
	.pitem-box-item {
		width: 33.333333%;
	}
	.ads {
		font-size: 1.1rem;
		font-weight: bold;
		padding: 6px 0 10px 0;
		color: #333;
	}
</style>
<style>
	.c_box_item .el-checkbox, .c_box_item .el-checkbox .el-checkbox__label {
		width: 100%;
	}
</style>
<template>
	<div class="dialog_page">
		<div class="dialog_page_header">
			<span style="padding-right: 20px;font-size: 1.2rem;">{{ pageParams.room_text }}</span>
		</div>
		<div class="dialog_page_body" style="height: 60vh;overflow-y: auto;overflow-x: hidden;padding: 20px;">
			<el-table :data="tableData" style="width: 100%" v-loading="tableLoading" height="100%">
				<el-table-column prop="order_num" label="订单号">
				</el-table-column>
				<el-table-column prop="pay_time" label="支付时间">
				</el-table-column>
				<el-table-column prop="sf_money" label="支付金额/元">
				</el-table-column>
				<el-table-column prop="yh_money" label="优惠金额/元">
				</el-table-column>
				<el-table-column prop="yh_money" label="">
					<template slot-scope="scope">
						<el-button type="success" size="mini" @click="openPrint(scope.row)">打印</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="table-fy">
			<el-pagination @current-change="handleCurrentChange" :current-page="page_num" :page-sizes="[20]"
				:page-size="page_size" layout="total, sizes, prev, pager, next, jumper" :total="table_total">
			</el-pagination>
		</div>
		<div class="dialog_page_footer">
			<el-button @click="clickCancel">取消</el-button>
		</div>
	</div>
</template>

<script>
	var _this;
	export default {
		props: ['pageParams'],
		components: {

		},
		data() {
			return {
				btnSaveLoading: false,
				tableLoading: true,
				tableData: [],
				page_num: 1,
				page_size: 20,
				table_total: 0
			};
		},
		created: function() {
			_this = this;
			_this.getOrder();
		},
		mounted: function() {},
		methods: {
			/**   
			 * @discribe 分页
			 * @author:郑凯 
			 * @create_time:2021-3-23 10:55:22 
			 */
			handleCurrentChange(val) {
				_this.page_num = val;
				_this.getOrder();
			},
			/**   
			 * @discribe 获取可打印订单
			 * @author:郑凯 
			 * @create_time:2021-3-22 10:52:00 
			 */
			getOrder() {
				_this.tableLoading = true;
				_this._postApi('/wy/charge/getBillOrderList', {
						room_id: _this.pageParams.room_id,
						page: _this.page_num,
						limit: _this.page_size
					})
					.then(res => {
						if (res.code == 1) {
							_this.table_total = res.data.count;
							_this.tableData = res.data.list;
							_this.tableLoading = false;
						} else {
							console.log(res);
						}
					})
					.catch(err => {
						console.log(err);
					});
			},

			/**   
			 * @discribe 打印
			 * @author:郑凯 
			 * @create_time:2021-3-23 11:03:39 
			 */
			openPrint(data) {
				_this._postApi('/wy/charge/billPrint', {
						oid: data.id
					})
					.then(res => {
						if (res.code == 1) {
							window.open(res.data.url)
						} else {
							console.log(res);
						}
					})
					.catch(err => {
						console.log(err);
					});
			},


			// _ ** 点击取消
			clickCancel() {
				_this.$emit('childrenEvent', {
					type: 0
				});
			},

			/**
			 * 消息弹框
			 * @author:郑凯
			 * @create_time:2021-3-19 11:18:23
			 */
			customMes(msg) {
				_this.btnSaveLoading = false;
				_this
					.$confirm(msg, '提示', {
						confirmButtonText: '确定',
						showClose: false,
						showCancelButton: false,
						type: 'warning'
					})
					.then(() => {})
					.catch(() => {});
			}
		}
	};
</script>

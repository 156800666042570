<style scoped>
	.dialog_page_header{
		font-size: 1.1rem;
		font-weight: bold;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding:0 0 10px 0;
	}
	.dialog_page_header i{
		font-size: 1.5rem;
	}
	.dialog_page_footer {
		text-align: right;
		padding: 20px 0 0 0;
		border-top: 1px solid #F1F1F1;
	}
	.arrears-box{
		padding:10px 0 20px 0;
	}
	.item-row-item{
		display: flex;
		align-items: center;
		padding: 10px 0;
	}
	.item-row{
		border: 5px solid #F5F5F5;
		padding: 10px;
	}
	.row-item-item{
		display: flex;
		align-items: center;
	}
	.item-row-item .row-item-item:nth-child(1){
		width: 30%;
	}
	.item-row-item-label{
		font-weight: bold;
	}
	.item-row-item-value{
		padding-left: 10px;
		font-size: 1.1rem;
	}
</style>
<style>
	.p_202104011229 .el-dialog__headerbtn{
		top: 0;
	}
</style>
<template>
	<div class="dialog_page p_202104011229">
		<div class="dialog_page_header">
			<div class="header-item">
				{{ pageParams.community.name }}欠费信息查看
			</div>
			<div class="header-item">
				<i class="el-icon-close" @click="clickCancel()"></i>
			</div>
		</div>
		<div class="dialog_page_body" v-loading="loading" style="min-height: 250px;max-height: 50vh;overflow-y: auto;">
			<div class="arrears-box" v-if="!loading">
				
				<div class="item-row">
					<div class="item-row-item">
						总欠费金额和分项金额含历史导入欠费金额
					</div>
					<div class="item-row-item">
						<div class="row-item-item">
							<div class="item-row-item-label">欠费总金额/元:</div>
							<div class="item-row-item-value">{{data.qf_total.qf_total}}</div>
						</div>
						<div class="row-item-item">
							<div class="item-row-item-label">违约总金额/元:</div>
							<div class="item-row-item-value">{{data.qf_total.wy_total}}</div>
						</div>
					</div>
					<div class="item-row-item" v-for="item in data.qf_total.item_total">
						<div class="row-item-item">
							<div class="item-row-item-label">{{item.item_name}}/元:</div>
							<div class="item-row-item-value">{{item.qf}}</div>
						</div>
						
						<div class="row-item-item">
							<div class="item-row-item-label">违约/元:</div>
							<div class="item-row-item-value">{{item.wy}}</div>
						</div>
						
					</div>
				</div>
				
				<div class="item-row">
					<div class="item-row-item">
						<div class="row-item-item">
							<div class="item-row-item-label">历史导入欠费总金额/元:</div>
							<div class="item-row-item-value">{{data.his_total.qf_total}}</div>
						</div>
						<div class="row-item-item">
							<div class="item-row-item-label">历史导入违约总金额/元:</div>
							<div class="item-row-item-value">{{data.his_total.wy_total}}</div>
						</div>
					</div>
					<div class="item-row-item" v-for="item in data.his_total.item_total">
						<div class="row-item-item">
							<div class="item-row-item-label">{{item.item_name}}/元:</div>
							<div class="item-row-item-value">{{item.qf}}</div>
						</div>
						<div class="row-item-item">
							<div class="item-row-item-label">违约/元:</div>
							<div class="item-row-item-value">{{item.wy}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="dialog_page_footer">
			<el-button @click="clickCancel">关闭</el-button>
		</div>
	</div>
</template>

<script>
	var _this;
	export default {
		props: ['pageParams'],
		data() {
			return {
				loading:true,
				data: null,
			};
		},
		created: function() {
			_this = this;
			console.log(_this.pageParams)
			_this.apiGetData();
		},
		mounted: function() {},
		methods: {
			// _ ** 点击取消
			clickCancel() {
				_this.$emit('childrenEvent', {
					type: 0
				});
			},

			// _ ** 添加保存
			apiGetData() {
				var params = {};
				params.residence_id = _this.pageParams.community.id;
				_this
					._postApi('/wy/charge/costQFTotal', params)
					.then(res => {
						if (res.code == 1) {
							_this.data = res.data;
							_this.loading = false;
						} else {
							_this
								.$confirm(res.msg, '提示', {
									confirmButtonText: '确定',
									cancelButtonText: '取消',
									type: 'warning'
								})
								.then(() => {})
								.catch(() => {});
						}
					})
					.catch(err => {
						console.log(err);
					});
			},

			/**
			 * 消息弹框
			 * @author:郑凯
			 * @create_time:2021-3-19 11:18:23
			 */
			customMes(msg) {
				_this.btnSaveLoading = false;
				_this
					.$confirm(msg, '提示', {
						confirmButtonText: '确定',
						showClose: false,
						showCancelButton: false,
						type: 'warning'
					})
					.then(() => {})
					.catch(() => {});
			}
		}
	};
</script>

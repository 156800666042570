<style scoped>
	.dialog_page_footer {
		text-align: right;
	}
	.community_box {
		max-height: 12rem;
		overflow-y: auto;
	}
</style>

<template>
	<div class="dialog_page">
		<div class="dialog_page_header"></div>
		<div class="dialog_page_body">
			<el-form label-width="100px">
				<el-row :gutter="20">
					<el-col :span="24">
						<el-form-item label="">{{ pageParams.room_text }}</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20">
					<el-col :span="24">
						<el-form-item label="收费项目名称">
							<el-input placeholder="输入项目名称" v-model="formData.name"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20">
					<el-col :span="24">
						<el-form-item label="收费金额/元">
							<el-input placeholder="输入收费金额" v-model="formData.money"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20">
					<el-col :span="24">
						<el-form-item label="添加月份类型">
							<el-select v-model="chargeMonthType" placeholder="请选择" style="width: 100%;"
								@change="mounthType">
								<el-option v-for="item in bzOptions" :key="item.type" :label="item.name.toString()"
									:value="item.type">{{item.name}}</el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20" v-if="chargeMonthType==1">
					<el-col :span="24">
						<el-form-item label="指定月份">
							<el-date-picker v-if="chargeMonthType==1" style="width: 100%;" v-model="formData.charge_month" type="month"
								format="yyyy-MM" placeholder="选择开始月份" value-format="yyyy-MM"></el-date-picker>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20" v-if="chargeMonthType==2">
					<el-col :span="24">
						<el-form-item label="月份区间选择">
							<el-date-picker  v-if="chargeMonthType==2" style="width: 100%;" v-model="chargeMonthArr" type="monthrange"
								range-separator="至" start-placeholder="开始日期" value-format="yyyy-MM" end-placeholder="结束日期">
							</el-date-picker>
							<!-- <el-date-picker style="width: 100%;" v-model="formData.charge_month" type="month"
								format="yyyy-MM" placeholder="选择开始月份" value-format="yyyy-MM"></el-date-picker> -->
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
		</div>
		<div class="dialog_page_footer">
			<el-button @click="clickCancel">取消</el-button>
			<el-button type="success" :loading="btnSaveLoading" @click="api_saveCommit">立即保存</el-button>
		</div>
	</div>
</template>

<script>
	var _this;
	export default {
		props: ['pageParams'],
		data() {
			return {
				btnSaveLoading: false,
				pickerOptions: {},
				formData: {
					name: '',
					money: '',
					room_id: '',
					charge_month: ''
				},
				options: [],
				bzOptions: [{
					"type": 1,
					"name": "单月"
				}, {
					"type": 2,
					"name": "月份区间"
				}],
				chargeMonthType: 1,
				chargeMonthArr: []
			};
		},
		created: function() {
			_this = this;
			// _this.pickerOptions = {
			// 	disabledDate(time) {
			// 		var before = new Date(_this.pageParams.mounths.start_month).getTime(); // 今天之前的日期
			// 		var last = new Date(_this.pageParams.mounths.end_month).getTime(); // 六天后的日期
			// 		return time.getTime() < before || time.getTime() > last; // 小于当前之前的日期禁止，大于6天后的日期禁止
			// 	}
			// };
		},
		mounted: function() {},
		methods: {
			// _ ** 点击取消
			clickCancel() {
				_this.$emit('childrenEvent', {
					type: 'close'
				});
			},

			/**   
			 * @discribe 
			 * @author:郑凯 
			 * @create_time:2021-3-25 15:35:35 
			 */
			mounthType(val) {

			},

			// _ ** 添加保存
			api_saveCommit() {
				_this.btnSaveLoading = true;
				var params = _this.formData;
				if (params.name == '') {
					_this.customMes('项目名必填!');
					return;
				}
				params.type = _this.chargeMonthType;
				params.room_id = _this.pageParams.room_id;
				if(_this.chargeMonthType ==2){
					params.charge_month = _this.chargeMonthArr;
				}
				//params.charge_month = _this.pageParams.charge_month;
				// console.log(params.money)
				//var mo = new Number(params.money);
				// params.money = mo.toFixed(2);

				_this
					._postApi('/wy/charge/ownItemAdd', params)
					.then(res => {
						if (res.code == 1) {
							_this.$emit('childrenEvent', {
								type: 'addCustomItem',
								params: params
							});
							_this.btnSaveLoading = false;
						} else {
							_this
								.$confirm(res.msg, '提示', {
									confirmButtonText: '确定',
									cancelButtonText: '取消',
									type: 'warning'
								})
								.then(() => {})
								.catch(() => {});
						}
					})
					.catch(err => {
						console.log(err);
					});
			},

			/**
			 * 消息弹框
			 * @author:郑凯
			 * @create_time:2021-3-19 11:18:23
			 */
			customMes(msg) {
				_this.btnSaveLoading = false;
				_this
					.$confirm(msg, '提示', {
						confirmButtonText: '确定',
						showClose: false,
						showCancelButton: false,
						type: 'warning'
					})
					.then(() => {})
					.catch(() => {});
			}
		}
	};
</script>

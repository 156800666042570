<style scoped>
	.dialog_page_footer {
		text-align: right;
	}
	.community_box {
		max-height: 12rem;
		overflow-y: auto;
	}
</style>

<template>
	<div class="dialog_page">
		<div class="dialog_page_header"></div>
		<div class="dialog_page_body">
			<el-form label-width="100px">
				<el-row :gutter="20">
					<el-col :span="24">
						<el-form-item label="" style="font-weight: bold;">选择月份区间</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20">
					<el-col :span="24">
						<el-form-item label="月份区间选择">
							<el-date-picker style="width: 100%;" v-model="chargeMonthArr" type="monthrange"
								range-separator="至" start-placeholder="开始日期" value-format="yyyy-MM" end-placeholder="结束日期">
							</el-date-picker>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
		</div>
		<div class="dialog_page_footer">
			<el-button @click="clickCancel">取消</el-button>
			<el-button type="success" :loading="btnSaveLoading" @click="api_saveCommit">立即导出</el-button>
		</div>
	</div>
</template>

<script>
	var _this;
	export default {
		props: ['pageParams'],
		data() {
			return {
				btnSaveLoading: false,
				chargeMonthArr: []
			};
		},
		created: function() {
			_this = this;
		},
		mounted: function() {},
		methods: {
			// _ ** 点击取消
			clickCancel() {
				_this.$emit('childrenEvent', {
					type: 'close'
				});
			},

			/**   
			 * @discribe 
			 * @author:郑凯 
			 * @create_time:2021-3-25 15:35:35 
			 */
			mounthType(val) {

			},

			// _ ** 添加保存
			api_saveCommit() {
				_this.btnSaveLoading = true;
				
				if(!_this.chargeMonthArr[0]){
					_this.customMes("请选择月份");
					return;
				}
				let params = {
					start_month:_this.chargeMonthArr[0],
					end_month:_this.chargeMonthArr[1],
					residence_id:_this.pageParams.r_id
				}
				_this.btnSaveLoading = false;
				_this.$emit('childrenEvent', {
					type: 1,
					params:params
				});
			},

			/**
			 * 消息弹框
			 * @author:郑凯
			 * @create_time:2021-3-19 11:18:23
			 */
			customMes(msg) {
				_this.btnSaveLoading = false;
				_this
					.$confirm(msg, '提示', {
						confirmButtonText: '确定',
						showClose: false,
						showCancelButton: false,
						type: 'warning'
					})
					.then(() => {})
					.catch(() => {});
			}
		}
	};
</script>

<style scoped>
	.tpm_box {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
	}
	.tem_body {
		flex: 1;
		width: 100%;
	}
	.tem_header {
		padding: 5px;
		display: flex;
		align-items: center;
	}
	.tem_body_contair {
		height: 100%;
		width: 100%;
	}
	.tem_footer {
		text-align: right;
		padding: 5px;
	}
	.mb {
		padding: 0 20px 0 10px;
	}
	/* 弹窗 */
	.el-dialog__wrapper {
		position: absolute;
		left: 0;
		top: 0;
		display: flex;
		align-items: center;
		overflow: hidden;
	}
	.el-dialog.my_dialog {
		margin-top: 0;
	}
	/* 房间信息 */
	.room_info {
		padding: 0;
	}
	button.el-button.sear-bb.el-button--default {
		background-color: #13ce66;
		color: #fff;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
	.mb span {
		font-size: 1.2rem;
	}
</style>
<style>
	table {
		box-sizing: border-box;
	}
	/* 用来设置当前页面element全局table 选中某行时的背景色*/
	.el-table__body tr.current-row>td {
		background-color: #fff !important;
		color: #333;
	}
	.mb .el-breadcrumb__inner {
		font-weight: bold !important;
		font-size: 1.1rem;
	}
	.el-table__expanded-cell[class*='cell'] {
		padding: 5px;
		background-color: rgb(175, 246, 192);
	}
	.el-table .el-table__expanded-cell:hover {
		background-color: rgb(175, 246, 192) !important;
	}
	.el-table .tableRowClassName {
		background: oldlace;
	}
	.my_dialog.ml .el-dialog__header {
		padding: 0 !important;
	}
</style>
<template>
	<div class="tpm_box">
		<div class="tem_header" style="padding: 10px 0;">
			<div class="mb">
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<span>已选择：</span>
					<span>{{ buildPageParams.community.name }}</span>
				</el-breadcrumb>
			</div>

			<!-- <el-button type="danger" size="small">
				刷新
				<i class="el-icon-refresh-right"></i>
			</el-button> -->

			<div class="header_item" style="margin-left: 20px;">
				<el-input placeholder="请输入房屋唯一编号" v-model="room_code" size="small" class="input-with-select">
					<el-button slot="append" icon="el-icon-search" size="small" class="sear-bb" @click="goSerch()">搜索
					</el-button>
				</el-input>
			</div>
			<div class="header_item"
				style="flex:1;margin-left: 20px;justify-content: flex-end;display: flex;padding-right: 10px;">
				<el-button size="small" type="success" style="margin-left: 10px;" @click="openOfflineCharging()">收费
				</el-button>
				<el-button size="small" type="primary" style="margin-left: 10px;" @click="openAdd()">自定义收费项目
				</el-button>
				<!-- <el-button size="small" type="warning" style="margin-left: 10px;" @click="openDgFileUpload()">历史欠费批量导入
				</el-button>
				<el-button size="small" type="warning" style="margin-left: 10px;" @click="openDgFileUploadCc()">已收费批量导入
				</el-button> -->
				<!-- <el-button size="small" type="warning" style="margin-left: 10px;" @click="opendgArrearsInfo()">欠费信息查看
				</el-button> -->

			</div>
			<div class="header_item" style="margin-right: 20px;">
				<el-button size="small" type="warning" style="margin-left: 10px;" @click="openBillPrinting()">票据打印
				</el-button>
			</div>
		</div>
		<!-- <div class="more-btn-box" style="display: flex;justify-content: flex-end;padding-right: 10px;">
			<el-button size="small" type="warning" style="margin-left: 10px;display: inline-block;" @click="histChu()">
				历史欠费导出
			</el-button>
			<el-button size="small" type="warning" style="margin-left: 10px;display: inline-block;" @click="hasChu()">
				已缴费导出
			</el-button>
		</div> -->
		<div class="room_info">
			<!-- {{buildPageParams}} -->
			<span v-if="room_code == ''" style="font-size: 1.6rem;color: red;">请选择房屋或者搜索房屋</span>
		</div>

		<div class="tem_body" id="tem_body">
			<div class="tem_body_contair" id="table_box" v-loading="tableLoading" v-if="!tableLoading">
				<el-table :data="tableData" :height="tableHeight" border row-key="id">
					<el-table-column prop="charge_month" label="月份" width="">
						<template slot-scope="scope">
							{{ scope.row.charge_month }}
							<span v-if="scope.row.pay_status" style="color: red;font-weight: bold;"> &nbsp; &nbsp;
								({{ scope.row.pay_status }})</span>
						</template>
					</el-table-column>
					<el-table-column prop="ys_total" label="应收/元" width=""></el-table-column>
					<!-- <el-table-column prop="item_name" label="项目详情" width="400">
						<template slot-scope="scope">
							<el-table :data="scope.row.list" style="width: 100%">
								<el-table-column prop="item_name" label="项目"></el-table-column>
								<el-table-column prop="pay_status_text" label="状态"></el-table-column>
								<el-table-column prop="ys_fy" label="应收费用/元"></el-table-column>
							</el-table>
						</template>
					</el-table-column> -->
					<el-table-column prop="item_name" label="" width="260">
						<template slot-scope="scope">
							<el-popover placement="right" width="500" trigger="click">
								<div class="info_pop">
									<el-table :data="scope.row.list" style="width: 100%">
										<el-table-column prop="item_name" label="项目"></el-table-column>
										<el-table-column prop="pay_status_text" label="状态"></el-table-column>
										<el-table-column prop="ys_fy" label="应收费用/元"></el-table-column>
										<!-- <el-table-column prop="ys_fy" label="操作">
											<template slot-scope="scope1">
												<el-button type="text"><span style="color: red;"
														@click="deleteItem(scope1.row)">删除</span></el-button>
											</template>
										</el-table-column> -->
									</el-table>
								</div>
								<el-button type="text" slot="reference">查看详情</el-button>
							</el-popover>


						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
		<div class="tem_footer">
			<!-- <el-pagination @current-change="handleCurrentChange" :current-page="page_num" :page-sizes="[20]"
				:page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
			</el-pagination> -->
		</div>

		<el-dialog custom-class="my_dialog" width="35%" top="none" title="" :visible.sync="bindStandardDialog"
			destroy-on-close>
			<bind_standard v-if="bindStandardDialog" :pageParams="dialogOfflineChargingParam"
				v-on:childrenEvent="bindStandardEvent"></bind_standard>
		</el-dialog>

		<el-dialog custom-class="my_dialog" width="80%" top="none" title="" :visible.sync="dialogOfflineCharging"
			destroy-on-close :close-on-click-modal="false">
			<OfflineCharging v-if="dialogOfflineCharging" :pageParams="dialogOfflineChargingParam"
				v-on:childrenEvent="offlineChargingEvent"></OfflineCharging>
		</el-dialog>

		<el-dialog custom-class="my_dialog ml" width="90%" top="none" title="" :visible.sync="dialogBillPrinting"
			destroy-on-close>
			<bill_printing v-if="dialogBillPrinting" :pageParams="dialogOfflineChargingParam"
				v-on:childrenEvent="billPrintingEvent"></bill_printing>
		</el-dialog>
		<!-- 批量模板 -->
		<el-dialog custom-class="my_dialog ml" width="30%" top="none" title="" :visible.sync="dgFileUpload"
			destroy-on-close>
			<file_upload v-if="dgFileUpload" :pageParams="dgFileUploadParam" v-on:childrenEvent="dgFileUploadEvent">
			</file_upload>
		</el-dialog>

		<!-- 批量模板已缴费 -->
		<el-dialog custom-class="my_dialog ml" width="30%" top="none" title="" :visible.sync="dgFileUploadCc"
			destroy-on-close>
			<file_uploadCc v-if="dgFileUploadCc" :pageParams="dgFileUploadParamCc"
				v-on:childrenEvent="dgFileUploadEventCc">
			</file_uploadCc>
		</el-dialog>

		<!-- 欠费信息查看 -->
		<el-dialog custom-class="my_dialog ml" width="60%" top="none" title="" :visible.sync="dgArrearsInfo"
			destroy-on-close :show-close="false">
			<ArrearsInfo v-if="dgArrearsInfo" :pageParams="dgArrearsInfoParam" v-on:childrenEvent="dgArrearsInfoEvent">
			</ArrearsInfo>
		</el-dialog>

		<!-- 已欠费导出条件 -->
		<el-dialog custom-class="my_dialog ml" width="40%" top="none" title=""
			:visible.sync="dialod_costYJDownCondition" destroy-on-close :show-close="false">
			<CostYJDownCondition v-if="dialod_costYJDownCondition" :pageParams="dialod_costYJDownCondition_param"
				v-on:childrenEvent="dialod_costYJDownCondition_event">
			</CostYJDownCondition>
		</el-dialog>
	</div>
</template>

<script>
	import bind_standard from './bind_standard.vue';
	import OfflineCharging from './OfflineCharging.vue';
	import bill_printing from './bill_printing.vue';
	import file_upload from './file_upload.vue';
	import file_uploadCc from './file_uploadCc.vue';
	import ArrearsInfo from './ArrearsInfo';
	import CostYJDownCondition from './CostYJDownCondition';
	var _this;
	export default {
		components: {
			bind_standard,
			OfflineCharging: OfflineCharging,
			bill_printing: bill_printing,
			file_upload: file_upload,
			file_uploadCc: file_uploadCc,
			ArrearsInfo: ArrearsInfo,
			CostYJDownCondition: CostYJDownCondition
		},
		props: ['buildPageParams'],
		data() {
			return {
				tableHeight: 0,
				tableLoading: false,
				bindStandardDialog: false,
				pageTotal: 0,
				pageSize: 20,
				page_num: 1,
				tableData: [],
				room_code: '',
				ttt: '',
				dialogOfflineCharging: false,
				dialogOfflineChargingParam: null,
				dialogBillPrinting: false, //票据打印dialog
				dgFileUpload: false, //批量模板导入dialog
				dgFileUploadParam: null,
				dgFileUploadCc: false, //批量模板导入dialog已缴费
				dgFileUploadParamCc: null,

				dgArrearsInfo: false, //欠费信息查看
				dgArrearsInfoParam: null,

				dialod_costYJDownCondition: false,
				dialod_costYJDownCondition_param: null
			};
		},
		created: function() {
			_this = this;
			if (_this.buildPageParams.room) {
				_this.room_code = _this.buildPageParams.room.code;
				_this.getColletList();
			}
		},
		mounted: function() {
			_this.$nextTick(function() {
				_this.tableHeight = document.getElementById('tem_body').offsetHeight;
			});
		},
		methods: {
			/**
			 * 搜索
			 * @author:郑凯
			 * @create_time:2021-3-18 11:40:10
			 */
			goSerch() {
				_this.page_num = 1;
				_this.getColletList();
			},
			/** --------> 方法介绍: 切换分页
				author:郑凯 创建时间:2021-1-25 13:43:40 */
			handleCurrentChange(val) {
				_this.page_num = val;
				_this.getColletList();
			},

			/**
			 * 获取收费列表
			 * @author:郑凯
			 * @create_time:2021-3-18 11:33:22
			 */
			getColletList() {
				_this.tableLoading = true;
				_this
					._postApi('/wy/charge/getListByRoom', {
						page: _this.page_num,
						limit: 20,
						residence_id: _this.buildPageParams.community.id,
						room_code: _this.room_code
					})
					.then(function(res) {
						//console.log(res);
						_this.tableData = res.data.list;
						_this.tableLoading = false;
					})
					.catch(function() {});
			},

			/**
			 * 添加收费项目
			 * @author:郑凯
			 * @create_time:2021-3-18 12:35:18
			 */
			openAdd() {
				if (!_this.tableData[0]) {
					_this
						.$confirm('请先选择房间或者搜索房间', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						})
						.then(() => {})
						.catch(() => {});
					return;
				}
				var dialogOfflineChargingParam = {};
				dialogOfflineChargingParam.room_id = _this.tableData[0].room_id;
				dialogOfflineChargingParam.room_text = _this.tableData[0].room_text;
				_this.dialogOfflineChargingParam = dialogOfflineChargingParam;
				_this.bindStandardDialog = true;
			},
			bindStandardEvent(e) {
				if (e.type == 'close') {
					_this.bindStandardDialog = false;
				} else if (e.type == 'addCustomItem') {
					_this.goSerch();
					_this.bindStandardDialog = false;
				}
			},

			/**
			 *
			 * @author:郑凯
			 * @create_time:2021-3-19 16:00:32
			 */
			deleteItem(obj) {
				const h = this.$createElement;
				this.$msgbox({
					title: '消息',
					type: "warning",
					message: h('p', null, [h('span', null, '确定删除项目 '), h('span', {
						style: 'color: teal'
					}, obj.item_name)]),
					showCancelButton: true,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					beforeClose: (action, instance, done) => {
						if (action === 'confirm') {
							instance.confirmButtonLoading = true;
							instance.confirmButtonText = '执行中...';

							_this
								._postApi('/wy/charge/itemDel', {
									cost_id: obj.id,
									room_id: _this.tableData[0].room_id
								})
								.then(function(res) {
									done();
									_this.goSerch();
									setTimeout(() => {
										instance.confirmButtonLoading = false;
									}, 300);
								})
								.catch(function() {});
						} else {
							done();
						}
					}
				}).then(action => {

				});
			},

			/**
			 * 线下缴费
			 * @author:郑凯
			 * @create_time:2021-3-18 15:08:36
			 */
			openOfflineCharging() {
				
				if (!_this.tableData[0]) {
					_this
						.$confirm('请先选择房间或者搜索房间', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						})
						.then(() => {})
						.catch(() => {});
					return;
				}
				var dialogOfflineChargingParam = {};
				dialogOfflineChargingParam.room_id = _this.tableData[0].room_id;
				dialogOfflineChargingParam.room_text = _this.tableData[0].room_text;
				_this.dialogOfflineChargingParam = dialogOfflineChargingParam;
				_this.dialogOfflineCharging = true;
			},
			offlineChargingEvent(e) {
				if (e.type == 'close') {
					_this.dialogOfflineCharging = false;
				} else if (e.type == 'success') {
					_this.goSerch();
					_this.dialogOfflineCharging = false;
				}
			},

			/**   
			 * @ 票据打印
			 * @author:郑凯 
			 * @create_time:2021-3-22 10:41:54 
			 */
			openBillPrinting() {
				// console.log(_this.buildPageParams)
				if (!_this.buildPageParams.room) {
					_this
						.$confirm('请先选择房间或者搜索房间', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						})
						.then(() => {})
						.catch(() => {});
					return;
				}
				var dialogOfflineChargingParam = {};
				// dialogOfflineChargingParam.room_id = _this.tableData[0].room_id;
				// dialogOfflineChargingParam.room_text = _this.tableData[0].room_text;
				dialogOfflineChargingParam.room_id = _this.buildPageParams.room.id;
				dialogOfflineChargingParam.room_text = _this.buildPageParams.room.name;
				_this.dialogOfflineChargingParam = dialogOfflineChargingParam;
				_this.dialogBillPrinting = true;
			},
			billPrintingEvent(e) {
				if (e.type == 0) {
					_this.dialogBillPrinting = false;
				}
			},

			/**   
			 * @discribe 批量模板导入
			 * @author:郑凯 
			 * @create_time:2021-3-25 15:53:29 
			 */
			openDgFileUpload() {
				var dialogOfflineChargingParam = {};
				dialogOfflineChargingParam.community = _this.buildPageParams.community;
				_this.dgFileUploadParam = dialogOfflineChargingParam;
				_this.dgFileUpload = true;
			},
			dgFileUploadEvent(e) {
				if (e.type == 0) {
					_this.dgFileUpload = false;
				} else if (e.type == 1) {
					_this.goSerch();
					_this.dgFileUpload = false;
				}
			},

			/**   
			 * @discribe 已收费导入
			 * @author:郑凯 
			 * @create_time:2021-4-2 11:47:38 
			 */
			openDgFileUploadCc() {
				var dialogOfflineChargingParam = {};
				dialogOfflineChargingParam.community = _this.buildPageParams.community;
				_this.dgFileUploadParamCc = dialogOfflineChargingParam;
				_this.dgFileUploadCc = true;
			},
			dgFileUploadEventCc(e) {
				if (e.type == 0) {
					_this.dgFileUploadCc = false;
				} else if (e.type == 1) {
					_this.goSerch();
					_this.dgFileUploadCc = false;
				}
			},

			/**   
			 * @discribe 欠费信息
			 * @author:郑凯 
			 * @create_time:2021-4-1 11:49:04 
			 */
			opendgArrearsInfo() {
				var dialogParam = {};
				dialogParam.community = _this.buildPageParams.community;
				_this.dgArrearsInfoParam = dialogParam;
				_this.dgArrearsInfo = true;
			},
			dgArrearsInfoEvent(e) {
				if (e.type == 0) {
					_this.dgArrearsInfo = false;
				}
			},

			/**   
			 * @discribe 历史欠费导出
			 * @author:郑凯 
			 * @create_time:2021-4-8 12:14:22 
			 */
			histChu() {
				_this._axios({
					method: 'get',
					params: {
						residence_id: _this.buildPageParams.community.id
					},
					url: '/wy/charge/costQFDown',
					headers: {
						"token": localStorage.getItem('token')
					},
					responseType: 'arraybuffer'
				}).then((response) => { 
					// console.log(response.getResponseHeaders('content-type'));
					//console.log(response)
					let blob = new Blob([response.data], {
						type: 'application/vnd.ms-excel;charset-UTF-8'
						// type: 'application/zip'
					})
					let downloadElement = document.createElement('a');
					let href = window.URL.createObjectURL(blob); //创建下载的链接
					downloadElement.href = href;
					downloadElement.download = _this.buildPageParams.community.name + '历史欠费.xlsx'; //下载后文件名
					document.body.appendChild(downloadElement);
					downloadElement.click(); //点击下载
					document.body.removeChild(downloadElement); //下载完成移除元素
					window.URL.revokeObjectURL(href); //释放掉blob对象
				}).catch(() => {

				});
			},

			/**   
			 * @discribe 已缴费导出
			 * @author:郑凯 
			 * @create_time:2021-4-12 12:23:00 
			 */
			hasChu() {
				_this.dialod_costYJDownCondition_param ={};
				_this.dialod_costYJDownCondition_param.r_id = _this.buildPageParams.community.id;
				_this.dialod_costYJDownCondition = true;
				return;

				
			},
			dialod_costYJDownCondition_event(e) {
				if(e.type == 1){
					_this._axios({
						method: 'get',
						params: e.params,
						url: '/wy/charge/costYJDown',
						headers: {
							"token": localStorage.getItem('token')
						},
						responseType: 'arraybuffer'
					}).then((response) => {
						// console.log(response.getResponseHeaders('content-type'));
						//console.log(response)
						let blob = new Blob([response.data], {
							type: 'application/vnd.ms-excel;charset-UTF-8'
							// type: 'application/zip'
						})
						let downloadElement = document.createElement('a');
						let href = window.URL.createObjectURL(blob); //创建下载的链接
						downloadElement.href = href;
						downloadElement.download = _this.buildPageParams.community.name + e.params.start_month +'至'+e.params.end_month + '已缴费.xlsx'; //下载后文件名
						document.body.appendChild(downloadElement);
						downloadElement.click(); //点击下载
						document.body.removeChild(downloadElement); //下载完成移除元素
						window.URL.revokeObjectURL(href); //释放掉blob对象
					}).catch(() => {
					
					});
				}else{
					_this.dialod_costYJDownCondition = false;
				}
			}
		}
	};
</script>

<style scoped>
    .dialog_page_header {
        padding: 3px;
    }

    .dialog_page_footer {
        text-align: right;
    }

    .community_box {
        max-height: 12rem;
    }

    .ddi {
        padding: 5px 0;
    }

    .c_box {
        display: flex;
        height: 100%;
    }

    .c_box_item {
        height: 100%;
        overflow-y: auto;
        flex: 1;
        padding: 10px 0;
        box-sizing: border-box;
        overflow-x: hidden;
    }

    .c_box .c_box_item:nth-child(2) {
        border-left: 1px solid #ccc;
    }

    .pitem-box {
        width: 100%;
        display: flex;
    }

    .pitem-box-item {
        width: 33.333333%;
    }

    .ads {
        font-size: 1.1rem;
        font-weight: bold;
        padding: 6px 0 10px 0;
        color: #333;
    }
</style>
<style>
    .c_box_item .el-checkbox, .c_box_item .el-checkbox .el-checkbox__label {
        width: 100%;
    }
</style>
<template>
    <div class="dialog_page">
        <el-dialog custom-class="my_dialog" append-to-body width="30%" top="none" title=""
                   :visible.sync="bindStandardDialog" destroy-on-close>
            <bind_standard v-if="bindStandardDialog" :pageParams="dialogPageParams"
                           v-on:childrenEvent="bindStandardEvent"></bind_standard>
        </el-dialog>

        <div class="dialog_page_header">
            <span style="padding-right: 20px;font-size: 1.2rem;">{{ pageParams.room_text }}</span>
            <div class="a" style="color: red;padding: 6px 0;" v-if="mounths">
                <span>请选择  {{ mounths.start_month }}  至  {{ mounths.end_month }}  之间月份；</span>
                <span style="color: #333;">请在确认收款之后再进行保存</span>
            </div>
            <el-date-picker
                    @change="changeMouth"
                    v-model="value1"
                    value-format="yyyy-MM"
                    type="monthrange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
            ></el-date-picker>
            <el-select v-model="pay_type" placeholder="请选择收费方式" style="margin-left: 40px;">
                <el-option v-for="item in payTypeOptions" :key="item.id" :label="item.name" :value="item.id">
                    {{item.name}}
                </el-option>
            </el-select>

            <el-date-picker
                    style="margin-left: 40px"
                    v-model="pay_time"
                    type="datetime"
                    placeholder="选择实际收费时间">
            </el-date-picker>

            <!-- <el-date-picker style="width: 150px;" v-model="value1" :picker-options="pickerOptions" type="month" format="yyyy-MM" placeholder="选择开始月份"></el-date-picker>
            至
            <el-date-picker style="width: 150px;" v-model="value1" :picker-options="pickerOptions" type="month" format="yyyy-MM" placeholder="选择结束月份"></el-date-picker>-->
            <!-- <el-button size="mini" style="margin-left: 10px;" type="success" @click="openAdd()">自定义收费项目</el-button> -->
        </div>
        <div class="dialog_page_body" style="height: 60vh;overflow-y: auto;overflow-x: hidden;padding: 20px;">
            <div class="c_box">
                <div class="c_box_item">
                    <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选
                    </el-checkbox>
                    <div style="margin: 15px 0;"></div>
                    <el-checkbox-group v-model="checkeditemsop" @change="handleCheckeditemsopChange">
                        <div class="ddi" v-for="(item,index) in itemsop" v-bind:key="item.id">

                            <el-checkbox :label="item.id" :key="item.id" @change="clickChec(item)">
                                <div v-if="index==0">
                                    <div class="ads">{{itemsop[0].charge_month}}</div>
                                </div>

                                <div v-if="itemsop[index-1]">
                                    <div class="ads" v-if="item.charge_month != itemsop[index-1].charge_month">
                                        {{itemsop[index].charge_month}}
                                    </div>
                                </div>


                                <div class="pitem-box">
                                    <div class="pitem-box-item"
                                         style="display: flex;justify-content: flex-start;align-items: center">
                                        {{ item.item_name }}
                                    </div>
                                    <div class="pitem-box-item"
                                         style="display: flex;justify-content: flex-start;align-items: center">
                                        <span>应收:</span>
                                        {{ item.ys_fy }}元
                                    </div>
                                    <div class="pitem-box-item" v-show="item.change">
                                        <span>实收:</span>
                                        <el-input placeholder="实收金额" v-model="item.ss" @input="xg"
                                                  style="width: 100px;line-height: 19px;padding-left: 5px">
                                        </el-input>
                                        元
                                    </div>
                                    <div class="pitem-box-item" v-show="!item.change">
                                        <span>月份:</span>
                                        {{ item.charge_month }}
                                    </div>
                                    <div class="pitem-box-item" v-show="item.change">
                                        <el-input placeholder="调整依据/最大20字" v-model="item.remark"
                                                  @input="xg"
                                                  style="width: auto;line-height: 19px">
                                        </el-input>
                                    </div>
                                </div>
                            </el-checkbox>
                        </div>
                    </el-checkbox-group>
                </div>
                <!-- <div class="c_box_item">
                    <el-checkbox-group v-model="checkedCustomItems">
                        <div class="ddi" v-for="item in customItems" v-bind:key="item.name">
                            <el-checkbox :label="item" :key="item.id">
                                <span style="width: 120px;display: inline-block;">{{ item.name }}</span>
                                <span style="margin-left: 20px;">
                                    <span>应收:</span>
                                    {{ item.money }}元
                                </span>
                                <span style="margin-left: 20px;">
                                    <span>月份:</span>
                                    {{ item.charge_month }}
                                </span>
                            </el-checkbox>
                        </div>
                    </el-checkbox-group>
                </div> -->
            </div>
        </div>
        <div class="dialog_page_footer">
			<span style="display: inline-block;margin-right: 20px;">
				<span>合计：</span>
				<span style="font-size: 1.5rem;">{{allPrice}}</span>
				<span>元</span>
			</span>
            <el-button @click="costChange">费用调整</el-button>
            <el-button @click="clickCancel">取消</el-button>
            <el-button type="success" :loading="btnSaveLoading" @click="api_saveCommit">立即保存</el-button>
        </div>
    </div>
</template>

<script>
    // import { sq_zq } from '../../assets/js/config.js'; //注意路径
    import bind_standard from './bind_standard.vue';

    var _this;
    export default {
        props: ['pageParams'],
        components: {
            bind_standard
        },
        data() {
            return {
                pickerOptions: {},
                mounths: null,
                btnSaveLoading: false,
                dialogSelectMounth: false,
                value1: '',
                bindStandardDialog: false,
                dialogPageParams: null,
                checkAll: false,
                checkeditemsop: [],
                itemsop: [],
                isIndeterminate: true,
                customItems: [], //自定义项目列表
                checkedCustomItems: [],
                payTypeOptions: [],
                pay_type: '',
                allPrice: 0,//合计
                pay_time:''//实际收款时间
            };
        },
        created: function () {
            _this = this;
            _this.getMounthList();
            _this.getPayType();
        },
        mounted: function () {
        },
        methods: {
            /**
             * 获取月份区间
             * @author:郑凯
             * @create_time:2021-3-18 15:52:14
             */
            getMounthList() {
                _this
                    ._postApi('/wy/charge/months', {
                        room_id: _this.pageParams.room_id
                    })
                    .then(res => {
                        if (res.code == 1) {
                            _this.mounths = res.data;
                            // _this.pickerOptions = {
                            // 	disabledDate(time) {
                            // 		//var timeNow = Date.now();
                            // 		//console.log(timeNow)
                            // 		// var before = timeNow - 24 * 60 * 60 * 1000; // 今天之前的日期
                            // 		// var last = timeNow + 6 * 24 * 60 * 60 * 1000; // 六天后的日期
                            // 		var before = new Date(res.data.start_month).getTime(); // 今天之前的日期
                            // 		var last = new Date(res.data.end_month).getTime(); // 六天后的日期
                            // 		return time.getTime() < before || time.getTime() > last; // 小于当前之前的日期禁止，大于6天后的日期禁止
                            // 	}
                            // };
                        } else {
                            console.log('err');
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    });
            },

            /**
             * 获取缴费方式
             * @author:郑凯
             * @create_time:2021-3-19 11:47:26
             */
            getPayType() {
                _this
                    ._getApi('/wy/charge/payType', null)
                    .then(res => {
                        if (res.code == 1) {
                            _this.payTypeOptions = res.data;
                        } else {
                            console.log('错误：获取缴费方式')
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    });
            },

            /**
             * changeMouth
             * @author:郑凯
             * @create_time:2021-3-18 16:50:09
             */
            changeMouth(val) {
                var before = new Date(_this.mounths.start_month).getTime();
                var last = new Date(_this.mounths.end_month).getTime();
                var sb = new Date(val[0]).getTime();
                var sl = new Date(val[1]).getTime();
                if (sb < before || sl > last) {
                    _this
                        .$confirm('请选择' + _this.mounths.start_month + '至' + _this.mounths.end_month + '之间的月份', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        })
                        .then(() => {
                        })
                        .catch(() => {
                        });
                    _this.value1 = '';
                    _this.itemsop = [];
                    _this.checkeditemsop = [];
                    return;
                }
                _this.getItems(val[0], val[1]);
            },

            /**
             * 获取项目
             * @author:郑凯
             * @create_time:2021-3-18 16:55:59
             */
            getItems(start_month, end_month) {
                _this.itemsop = [];
                _this.checkeditemsop = [];
                _this
                    ._postApi('/wy/charge/getNotPayItemsByMonth', {
                        room_id: _this.pageParams.room_id,
                        start_month: start_month,
                        end_month: end_month
                    })
                    .then(res => {
                        if (res.code == 1) {
                            _this.itemsop = res.data;
                            if (_this.itemsop.length > 0) {
                                _this.itemsop.forEach(item => {
                                    item.ss = item.ys_fy;
                                    item.remark = "";
                                })
                            }
                        } else {
                            _this
                                .$confirm(res.msg, '提示', {
                                    confirmButtonText: '确定',
                                    cancelButtonText: '取消',
                                    type: 'warning'
                                })
                                .then(() => {
                                })
                                .catch(() => {
                                });
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    });
            },

            // _ ** 点击取消
            clickCancel() {
                _this.$emit('childrenEvent', {
                    type: 'close'
                });
            },

            // _ ** 添加保存
            api_saveCommit() {


                var params = {
                    costs: []
                };
                for(let i=0;i<_this.itemsop.length;i++){
                    for (let  j=0;j<_this.checkeditemsop.length;j++){
                        if (_this.itemsop[i].id == _this.checkeditemsop[j]) {
                            if ((_this.itemsop[i].remark == "") && (_this.itemsop[i].ss != _this.itemsop[i].ys_fy)&&(_this.itemsop[i].change)) {
                                _this.customMes('费用调整请输入依据');
                                return;
                            }
                            let ss_money=_this.itemsop[i].ys_fy;
                            if(_this.itemsop[i].change){
                                ss_money=_this.itemsop[i].ss;
                            }
                            params.costs.push({
                                "cost_id": _this.itemsop[i].id,
                                "ss_money":ss_money ,
                                "remark": _this.itemsop[i].remark
                            })
                        }
                    }
                }
                params.room_id = _this.pageParams.room_id;
                params.pay_type = _this.pay_type;
                params.own_items = _this.checkedCustomItems;


                if (params.costs.length < 1) {
                    _this.customMes('项目至少选择一个!');
                    return;
                }
                if (params.pay_type == '') {
                    _this.customMes('支付方式必选!');
                    return;
                }
                if (_this.pay_time== ''){
                    _this.customMes('请选择实际收费时间!');
                    return;
                }
                params.pay_time = _this.dayjs(_this.pay_time).format("YYYY-MM-DD HH:mm:ss")

                _this.$confirm('确定此操作?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    _this.btnSaveLoading = true;
                    _this
                        ._postApi('/wy/charge/pay', params)
                        .then(res => {
                            if (res.code == 1) {
                                _this.btnSaveLoading = false;
                                this.$notify({
                                    title: '提示',
                                    message: '操作成功',
                                    type: 'success'
                                });
                                _this.$emit('childrenEvent', {
                                    type: 'success'
                                });
                            } else {
                                _this.btnSaveLoading = false;
                                _this
                                    .$confirm(res.msg, '提示', {
                                        confirmButtonText: '确定',
                                        showCancelButton: false,
                                        type: 'warning'
                                    })
                                    .then(() => {
                                    })
                                    .catch(() => {
                                    });
                            }
                        })
                        .catch(err => {
                            console.log(err);
                        });
                }).catch(() => {

                });




            },
            /**
             *
             * @author:郑凯
             * @create_time:2021-3-18 16:45:01
             */
            handleCheckAllChange(val) {
                var allId = [];
                _this.itemsop.forEach(function (item) {
                    allId.push(item.id);
                });
                this.checkeditemsop = val ? allId : [];
                if (val) {
                    // var allPrice = 0;
                    // _this.itemsop.forEach(function (item) {
                    //     var a = new Number(allPrice);
                    //     var b = new Number(item.ys_fy);
                    //     allPrice = a + b;
                    // });
                    // _this.allPrice = allPrice.toFixed(2);
                    _this.getTotalPrice();
                } else {
                    _this.allPrice = 0;
                }
                this.isIndeterminate = false;
            },
            handleCheckeditemsopChange(value) {

                let checkedCount = value.length;
                this.checkAll = checkedCount === this.itemsop.length;
                this.isIndeterminate = checkedCount > 0 && checkedCount < this.itemsop.length;
            },
            clickChec(obj) {
                // 取消联动选择
                // var baseData = _this.itemsop;
                // var allPrice = 0;
                // baseData.forEach(function (item) {
                //     _this.checkeditemsop.forEach(function (item_1) {
                //         if (item.id == item_1) {
                //         	if(item.change){
                // 				var a = new Number(allPrice);
                // 				var b = new Number(item.ss);
                // 				allPrice = a + b;
                // 			}else {
                // 				var a = new Number(allPrice);
                // 				var b = new Number(item.ys_fy);
                // 				allPrice = a + b;
                // 			}
                //         }
                //     })
                // })
                // _this.allPrice = allPrice.toFixed(2);
                _this.getTotalPrice();
                // _this.checkeditemsop = [];
                // var thisNowId = obj.id;
                // var baseData = _this.itemsop;
                // var timet = 0;

                // baseData.forEach(function(item){
                // 	if(item.id == thisNowId){
                // 		timet = new Date(item.charge_month).getTime();
                // 	}
                // })
                // var newArr = [];
                // var allPrice = 0;
                // //小于当前月份的全部选上
                // baseData.forEach(function(item){
                // 	var timett = new Date(item.charge_month).getTime();
                // 	if( timett > timet ==false){
                // 		newArr.push(item.id);
                // 		var a = new Number(allPrice);
                // 		var b = new Number(item.ys_fy);
                // 		allPrice = a+b;
                // 	}
                // })
                // _this.allPrice = allPrice.toFixed(2);
                // _this.checkeditemsop = newArr;
            },
            //计算总价
            getTotalPrice() {
                var baseData = _this.itemsop;
                var allPrice = 0;
                baseData.forEach(function (item) {
                    _this.checkeditemsop.forEach(function (item_1) {
                        if (item.id == item_1) {
                            if (item.change) {
                                var a = new Number(allPrice);
                                var b = new Number(item.ss);
                                allPrice = a + b;
                            } else {
                                var a = new Number(allPrice);
                                var b = new Number(item.ys_fy);
                                allPrice = a + b;
                            }
                        }
                    })
                })
                _this.allPrice = allPrice.toFixed(2);
            },
            /**
             *
             * @author:郑凯
             * @create_time:2021-3-18 16:45:07
             */
            openAdd() {
                var dialogOfflineChargingParam = {};
                dialogOfflineChargingParam.room_id = _this.pageParams.room_id;
                dialogOfflineChargingParam.room_text = _this.pageParams.room_text;
                dialogOfflineChargingParam.mounths = _this.mounths;
                _this.dialogPageParams = dialogOfflineChargingParam;
                _this.bindStandardDialog = true;
            },
            bindStandardEvent(e) {
                if (e.type == 'close') {
                    _this.bindStandardDialog = false;
                } else if (e.type == 'addCustomItem') {
                    _this.customItems.push(e.params);
                    _this.checkedCustomItems.push(e.params);
                    _this.bindStandardDialog = false;
                }
            },

            /**
             * 消息弹框
             * @author:郑凯
             * @create_time:2021-3-19 11:18:23
             */
            customMes(msg) {
                _this.btnSaveLoading = false;
                _this
                    .$confirm(msg, '提示', {
                        confirmButtonText: '确定',
                        showClose: false,
                        showCancelButton: false,
                        type: 'warning'
                    })
                    .then(() => {
                    })
                    .catch(() => {
                    });
            },
            /**
             * 费用调整
             * @author:wf
             * @create_time:2021-08-06 11:15:00
             */
            costChange(e) {
                if (e.target.innerText == "取消调整") {
                    let items = _this.itemsop;
                    items.forEach(function (item) {
                        item.change = false;
                        item.ss=item.ys_fy;
                        item.remark="";
                    })
                    e.target.innerText = "费用调整";
                    _this.getTotalPrice();
                } else {
                    e.target.innerText = "取消调整"
                    let items = _this.itemsop;
                    items.forEach(function (item) {
                        item.change = true;
                        item.ss=item.ys_fy;
                        item.remark="";
                    })
                    _this.getTotalPrice();
                }
                _this.$forceUpdate();
            },
            xg() {
                _this.getTotalPrice();
                _this.$forceUpdate();
            }
        }
    };
</script>

<style scoped>
	
</style>
<template>
	<div class="con_page_container">
		<!-- 左边数据树 -->
		<div class="left_tree_box">
			<el-tree :props="props" node-key="id" ref="tree"  :load="loadNode" lazy :show-checkbox="false"
			:expand-on-click-node="false" :highlight-current="true" @node-click="treeClick" @node-expand="nodeExpand"></el-tree>
		</div>
		<!-- 内容 -->
		<div class="main_container">
			<div class="ch_temp_box">
				<room :key="room_show_key" v-if="room_show_key>0" :buildPageParams="temPageParams"></room>
			</div>
		</div>
	</div>
</template>

<script>
	import build from './build.vue';
	import units from './units.vue';
	import room from './room.vue';
	var _this;
	export default {
		components:{
			room
		},
		data() {
			return {
				communityList:[],//小区列表
				props: {
					label: 'name',
					children: 'zones',
					isLeaf: 'leaf'
				},
				temViewIndex:0,
				temPageParams:{},
				room_show_key:-1
			}
		},
		created: function() {
			_this = this;
		},
		mounted: function() {

		},
		methods: {
			/* * 获取小区列表 tree加载 */
			loadNode(node, resolve) {
				if (node.level === 0) {
					//获取小区
					_this._getApi('/wy/account/getResidenceListByAdmin', {}).then((res) => {
						if (res.code == 1) {
							_this.communityList = res.data;
							_this.temPageParams.community = _this.communityList[0];
							_this.$nextTick(function(){
								_this.$refs.tree.setCurrentKey(_this.communityList[0].id);//获取已经设置的资源后渲染
								_this.room_show_key = 1;
							})
							return resolve(_this.communityList);
							
						} else {
					
						}
					}).catch((err) => {})
				}
				if (node.level === 1) {
					//获取小区楼宇
					_this._getApi('/wy/residenceBuild/getList', {
						residence_id:node.data.id
					}).then((res) => {
						if (res.code == 1) {
							return resolve(res.data.list);
						} else {
					
						}
					}).catch((err) => {});
				}
				if (node.level === 2) {
					//获取单元楼宇
					_this._getApi('/wy/residenceBuildUnit/getList', {
						build_id:node.data.id
					}).then((res) => {
						if (res.code == 1) {
							var baseData = res.data.list;
							baseData.forEach(function(item){
								item.name = item.name;
								item.leaf = false;
							})
							return resolve(baseData);
						} else {
					
						}
					}).catch((err) => {});
				}
				if (node.level === 3) {
					//获取房间
					_this._getApi('/wy/residenceBuildUnitRoom/getList', {
						unit_id:node.data.id
					}).then((res) => {
						if (res.code == 1) {
							var baseData = res.data.list;
							baseData.forEach(function(item){
								item.name = item.code;
								item.leaf = true;
							})
							return resolve(baseData);
						} else {
					
						}
					}).catch((err) => {});
				}
			},
			
			/* * 节点被展开 */
			nodeExpand(data,node,_this_){
				
			},
			/* * tree 点击 */
			treeClick(data,node,_this_){
				// 小区被点击
				_this.temPageParams={};
				if(node.level == 1){
					_this.temPageParams.community = data;
				}else if(node.level == 2){
					_this.temPageParams.community = node.parent.data;
				}else if(node.level == 3){
					_this.temPageParams.community = node.parent.parent.data;
				}else if(node.level == 4){
					_this.temPageParams.community = node.parent.parent.parent.data;
					_this.temPageParams.room = data;
				}
				_this.room_show_key +=1;
			},
			
			/* * 楼宇 组件通信 */
			buildEvent(e){
				switch(e.type){
					case 'add':
						var node = _this.$refs.tree.getNode(_this.selectCommunityId);   //要刷新的tree id
						node.loaded = false;
						node.expand();
					break;
				}
			},
			/* * 单元 组件通信 */
			unitsEvent(e){
				switch(e.type){
					case 'add':
						var node = _this.$refs.tree.getNode(_this.unitsPageParams.build.id);   //要刷新的tree id
						node.loaded = false;
						node.expand();
					break;
				}
			},
			
		}
	}
</script>
